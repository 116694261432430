import React from 'react';
import PropTypes from 'prop-types';

import FlexDiv from './FlexDiv';
import { Text } from './Texts';
import Separator from './Separator';

const Subtitle = ({ className, children, color, alignement }) => {
    return (
        <div
            className={className}
            style={{
                marginTop: -20,
                marginBottom: 20,
            }}
            css={`
                @media only screen and (max-width: 599px) {
                    padding: 0 30px !important;
                }
            `}
        >
            <FlexDiv
                style={{
                    justifyContent: alignement,
                    // borderBottomStyle: 'solid',
                    // borderBottomColor: theme.color[color],
                    // borderWidth: 6
                }}
            >
                <Text
                    special
                    style={{
                        fontSize: 50,
                        backgroundColor: 'transparent',
                        position: 'relative',
                        top: 20
                    }}
                >
                    {children}
                </Text>

            </FlexDiv>
            <Separator
                strokeColor={color}
                strokeWidth="6px"
                style={{
                    // marginLeft: 10,
                    // marginRight: 10,
                }}
            />
        </div>
    );
};

Subtitle.propTypes = {
    alignement: PropTypes.string,
    children: PropTypes.element,
    color: PropTypes.string,
};

Subtitle.defaultProps = {
    alignement: 'flex-start',
    color: 'vertFaible'
};

export default Subtitle;
