import React from 'react';
import styled, { withTheme } from 'styled-components';
import { Link, graphql } from 'gatsby';
import Image from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';

// import withTheme from '../theme';
import { Layout } from '../components1/layout';
import { Bold, Text as TextWithoutStyle, Title } from '../components1/Texts';
import LinedTitle from '../components1/LinedTitle';
import Separator from '../components1/Separator';
import Subtitle from '../components1/Subtitle';
import Section from '../components1/Section';
import FlexDiv from '../components1/FlexDiv';
import { VegIcon, FishIcon, MeatIcon, HeartIcon, TimeIcon } from '../components1/Icons';
import { CabadiciButton } from '../components1/buttons';
import RecipesSlider from '../containers/RecipesSlider';

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    grid-column-gap: 30px;
    grid-row-gap: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    grid-template-areas:
        'a b c'
        'd e f';
    @media only screen and (max-width: 599px) {
        grid-template-columns: 1fr;
        grid-template-areas:
            'a'
            'd'
            'b'
            'e'
            'c'
            'f';
    }
`;

const Text = styled(TextWithoutStyle)`
    padding: 5px 0;
    @media only screen and (max-width: 599px) {
        padding: 5px 30px !important;
    }
`;

const TitleResponsive = styled(Title)`
    @media only screen and (max-width: 599px) {
        padding-top: 10px;
        font-size: 1.4rem;
        /* margin-left: 50px; */
        white-space: nowrap;
    }
`;

const SectionPadding = styled(Section)`
    padding: 30px 150px;
    @media only screen and (max-width: 699px) {
        padding: 30px 30px !important;
    }
`;

const IndexPage = withTheme(({ data, theme }) => (
    <Layout>
        {
            ({ openConnectForm }) => (
                <>
                    <SectionPadding>
                        <TitleResponsive
                            style={{
                                textAlign: 'center'
                            }}
                        >{'UN CABADICI C\'EST QUOI ?'}</TitleResponsive>
                        <Subtitle
                            color="vertFaible"
                            alignement="flex-start"
                        >
                            un service
                        </Subtitle>
                        <Text>
                            Nous sélectionnons <Bold>les meilleurs produits</Bold> au <Bold>plus près</Bold> de chez nous.
                        </Text>
                        <Text>
                            Nous imaginons <Bold>des recettes de saison, rapides</Bold> à préparer et qui plaisent aussi aux <Bold>enfants.</Bold>
                        </Text>
                        <Text>
                            Nous vous livrons <Bold>{'tout ce qu\'il faut pour les cuisiner'}</Bold> et <Bold>où vous le souhaitez.</Bold>
                        </Text>
                    </SectionPadding>
                    <Image fluid={data.service.childImageSharp.fluid} alt="service"/>
                    <SectionPadding>
                        <Subtitle
                            color="rougeFaible"
                            alignement="flex-end"
                        >
                            des produits frais
                        </Subtitle>
                        <Text>
                            Nos poissons et nos viandes vous sont proposés <Bold>conditionnés sous-vide, déja portionnés.</Bold>
                        </Text>
                        <Text>
                            Vos produits frais sont livrés dans leur <Bold>pochette isotherme</Bold> avec une <Bold>plaquette de froid.</Bold>
                        </Text>
                    </SectionPadding>
                    <Image fluid={data.produits.childImageSharp.fluid} alt="produits"/>
                    <SectionPadding>
                        <Subtitle
                            color="jauneFaible"
                            alignement="flex-start"
                        >
                            une habitude
                        </Subtitle>
                        <Text>
                            Vous recevez le meilleur de notre région, grâce à un <Bold>abonnement ultra flexible.</Bold>
                        </Text>
                        <Text>
                            Vous maîtrisez votre budget avec un <Bold>prélèvement hebdomadaire sans engagement.</Bold>
                        </Text>
                    </SectionPadding>
                    <Image fluid={data.habitude.childImageSharp.fluid} alt="habitude"/>
                    <div
                        style={{
                            // backgroundImage: 'url(\'./images/PNG EXTRAITS PSD 261218/CONTENU/contenu insert test.png\')',
                            // backgroundRepeat: 'no-repeat',
                            // backgroundSize: 'cover',
                            backgroundColor: 'lightgrey',
                            width: '100%',
                            // height: 500,
                            padding: '30px 0',
                        }}
                    >
                        <FlexDiv
                            style={{
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: '1.5rem',
                                    textAlign: 'center',
                                }}
                            >Chaque semaine</Text>
                            <Text
                                special
                                style={{
                                    fontSize: '4rem',
                                    textAlign: 'center',
                                }}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        font-size: 3rem !important;
                                    }
                                `}
                            >
                                faites le choix de recevoir
                            </Text>
                            <Text
                                style={{fontSize: '1.5rem', paddingBottom: 20,}}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        font-size: 1.15rem !important;
                                    }
                                `}
                            >
                                <Bold
                                    size={20}
                                >2</Bold>,
                                <Bold
                                    size={24}
                                > 3</Bold>,
                                <Bold
                                    size={28}
                                > 4 </Bold>
                                 ou
                                <Bold
                                    size={32}
                                > 5 </Bold>
                                recettes parmi :
                            </Text>
                            <FlexDiv
                                style={{
                                    flexDirection: 'column',
                                }}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        padding-left: 20px !important;
                                    }
                                `}
                            >
                                <FlexDiv style={{alignItems: 'center', padding: '5px 0',}}>
                                    <VegIcon />
                                    <Text style={{paddingLeft: 20}}>La recette <Bold>végétarienne</Bold></Text>
                                </FlexDiv>
                                <FlexDiv style={{alignItems: 'center', padding: '5px 0',}}>
                                    <FishIcon />
                                    <Text style={{paddingLeft: 20}}>La recette à base de <Bold>poisson</Bold></Text>
                                </FlexDiv>
                                <FlexDiv style={{alignItems: 'center', padding: '5px 0',}}>
                                    <MeatIcon />
                                    <Text style={{paddingLeft: 20}}>La recette à base de <Bold>viande</Bold></Text>
                                </FlexDiv>
                                <FlexDiv style={{alignItems: 'center', padding: '5px 0',}}>
                                    <TimeIcon />
                                    <Text style={{paddingLeft: 20}}>La recette qui <Bold>prend son temps</Bold></Text>
                                </FlexDiv>
                                <FlexDiv style={{alignItems: 'center', padding: '5px 0',}}>
                                    <HeartIcon />
                                    <Text style={{paddingLeft: 20}}>La recette coup de cœur <Bold>du mois</Bold></Text>
                                </FlexDiv>
                            </FlexDiv>
                        </FlexDiv>
                        <FlexDiv
                            style={{
                                justifyContent: 'center',
                                paddingTop: 50,
                            }}
                            css={`
                                @media only screen and (max-width: 599px) {
                                    flex-direction: column;
                                    padding-top: 20px !important;
                                }
                            `}
                        >
                            <Image
                                fixed={data.logoPrincipal.childImageSharp.fixed}
                                alt="logo" style={{alignSelf: 'center'}}
                            />
                            <FlexDiv
                                style={{
                                    flex:0,
                                    flexDirection: 'column',
                                    alignSelf: 'center',
                                    padding: 20
                                }}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        padding: 0 !important;
                                    }
                                `}
                            >
                                <Text
                                    style={{
                                        whiteSpace: 'nowrap',
                                        padding: 0,
                                        fontSize: '1.25rem'
                                    }}
                                    css={`
                                        font-face: ${props => props.theme.font.title};
                                        @media only screen and (max-width: 599px) {
                                            font-size: 0.85rem !important;
                                            white-space: normal !important;
                                            text-align: center;
                                            padding-bottom: 0 !important;
                                        }
                                    `}
                                >
                                        vous livre tous les ingrédients nécessaires
                                </Text>
                                <Text
                                    style={{
                                        whiteSpace: 'nowrap',
                                        padding: 0,
                                        fontSize: '1.25rem'
                                    }}
                                    css={`
                                        font-face: ${props => props.theme.font.title};
                                        @media only screen and (max-width: 599px) {
                                            font-size: 0.85rem !important;
                                            white-space: normal !important;
                                            text-align: center;
                                            padding-top: 0 !important;
                                        }
                                    `}
                                >
                                        pour préparer ces recettes pour 4 personnes.
                                </Text>
                            </FlexDiv>
                        </FlexDiv>
                    </div>
                    <FlexDiv style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                    }}>
                        <LinedTitle style={{padding: 0}}>
                            <Title
                                style={{
                                    padding: '10px 50px',
                                }}
                            >
                                Le prix
                            </Title>
                        </LinedTitle>
                    </FlexDiv>
                    <div
                        column style={{
                            backgroundColor: theme.color.beigeFaible,
                            alignItems: 'stretch'
                        }}
                    >
                        <Section>
                            <FlexDiv
                                style={{
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >
                                <RecipesSlider />
                            </FlexDiv>
                            <Text style={{
                                fontSize: 18,
                                fontFamily: theme.font.title,
                                paddingTop: 10,
                                paddingBottom: 10,
                                textAlign: 'center',
                            }}>
                                Une fois abonné, vous recevrez votre CABADICI chaque vendredi !
                            </Text>
                            <Text style={{
                                fontSize: 14,
                                paddingBottom: 20,
                                textAlign: 'center',
                            }}>
                                Personnalisez votre commande la semaine précédente en choisissant :
                                <br/>
                                les recettes que vous souhaitez cuisiner
                                <br/>
                                &
                                <br/>
                                la livraison qui vous convient
                            </Text>
                            <Text style={{
                                fontSize: 12,
                                paddingBottom: 20,
                                textAlign: 'center',
                            }}>
                                Pas besoin de CABADICI cette semaine ?
                                <br/>
                                suspendez votre abonnement en un clic dans votre espace personnel,
                                <br/>
                                rubrique « LES LIVRAISONS ».
                            </Text>
                            <FlexDiv
                                style={{
                                    justifyContent: 'center',
                                    paddingTop: 10,
                                }}
                            >
                                <CabadiciButton
                                    color="jauneFort"
                                    onClick={() => openConnectForm()}
                                    style={{width: 170}}
                                >
                                    commander
                                </CabadiciButton>
                            </FlexDiv>
                        </Section>
                    </div>
                    <Separator strokeColor="vertFort" style={{marginTop: 5}}/>
                    <Section>
                        <GridContainer>
                            <div
                                style={{
                                    gridArea: 'a',
                                    padding: 10,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <Bold
                                    style={{
                                        textAlign: 'center',
                                        maxWidth: 190,
                                        alignSelf: 'flex-start'
                                    }}
                                    css={`
                                        @media only screen and (max-width: 599px) {
                                            font-size: 0.875rem;
                                        }
                                    `}
                                >PAS LE TEMPS DE CHOISIR VOS RECETTES CETTE SEMAINE ?</Bold>
                            </div>
                            <div
                                style={{
                                    gridArea: 'd',
                                    padding: 10,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        // maxWidth: 170,
                                        padding: '0 20px',
                                        alignSelf: 'flex-start'
                                    }}
                                    css={`
                                        @media only screen and (max-width: 599px) {
                                            font-size: 0.875rem;
                                            padding: 0 !important;
                                        }
                                    `}
                                >
                                    Laissez vous guider,
                                    <br />
                                    nous reprendrons les informations
                                    <br />
                                    de votre dernière commande
                                    <br />
                                    (nombre et type de recette,
                                    <br />
                                    mode et créneau de livraison)
                                    <br />
                                    et vous recevrez votre CABADICI
                                    <br />
                                    sans y penser !
                                </Text>
                            </div>
                            <div
                                style={{
                                    gridArea: 'b',
                                    padding: 10,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <Bold
                                    style={{
                                        textAlign: 'center',
                                        maxWidth: 190,
                                        alignSelf: 'flex-start'
                                    }}
                                    css={`
                                        @media only screen and (max-width: 599px) {
                                            font-size: 0.875rem;
                                        }
                                    `}
                                >VOUS SOUHAITEZ SUSPENDRE OU RÉSILIER VOTRE ABONNEMENT ?</Bold>
                            </div>
                            <div
                                style={{
                                    gridArea: 'e',
                                    padding: 10,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        // maxWidth: 170,
                                        padding: '0 20px',
                                        alignSelf: 'flex-start'
                                    }}
                                    css={`
                                        @media only screen and (max-width: 599px) {
                                            font-size: 0.875rem;
                                            padding: 0 !important;
                                        }
                                    `}
                                >
                                    Connectez vous dans votre espace
                                    <br />
                                    utilisateur et modifiez vos préférences
                                    <br />
                                    en un clic.
                                    <br />
                                    {'Attention, l\'abonnement vous faisant bénéficier'}
                                    <br />
                                    {'d\'un tarif préférentiel,'}
                                    <br />
                                    la résiliation sera possible après deux CABADICI commandés.
                                </Text>
                            </div>
                            <div
                                style={{
                                    gridArea: 'c',
                                    padding: 10,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <Bold
                                    style={{
                                        textAlign: 'center',
                                        maxWidth: 190,
                                        alignSelf: 'flex-start'
                                    }}
                                    css={`
                                        @media only screen and (max-width: 599px) {
                                            font-size: 0.875rem;
                                        }
                                    `}
                                >VOUS SOUHAITEZ ESSAYER CABADICI SANS VOUS ABONNER ?</Bold>
                            </div>
                            <div
                                style={{
                                    gridArea: 'f',
                                    padding: 10,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        // maxWidth: 170,
                                        padding: '0 20px',
                                        alignSelf: 'flex-start'
                                    }}
                                    css={`
                                        @media only screen and (max-width: 599px) {
                                            font-size: 0.875rem;
                                            padding: 0 !important;
                                        }
                                    `}
                                >
                                    Au moment de votre commande,
                                    <br />
                                    {'sélectionnez l\'option « sans abonnement ».'}
                                    <br />
                                    Les tarifs de vos CABADICI
                                    <br />
                                    seront automatiquement modifiés.
                                    <br />
                                    Tarifs sans abonnements : + 5€ TTC
                                    <br />
                                    sur chaque CABADICI.
                                </Text>
                            </div>
                        </GridContainer>
                    </Section>
                    <Separator strokeColor="vertFort" />
                    <FlexDiv
                        style={{justifyContent: 'center', marginTop: -1, marginBottom: 10}}
                    >
                        <Link style={{ textDecoration: 'none' }} to="/faq">
                            <CabadiciButton
                                color="rougeFort"
                                style={{width: 170}}
                            >
                                foire aux questions
                            </CabadiciButton>
                        </Link>
                    </FlexDiv>
                </>
            )
        }
    </Layout>
));

export default IndexPage;


export const pageQuery = graphql`
    query {
        produits: file(relativePath: { eq: "concept/concept-produits.jpg" }) {
            ...fullWidthImage
        }
        habitude: file(relativePath: { eq: "concept/concept-habitude.jpg" }) {
            ...fullWidthImage
        }
        service: file(relativePath: { eq: "concept/concept-service.jpg" }) {
            ...fullWidthImage
        }
        logoPrincipal: file(relativePath: { eq: "logos/logo-noir.png" }) {
            childImageSharp {
                fixed(height: 80) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`
